<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Communication/Share.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          Введите группы рассылок для упрощения оповещений
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Группы рассылок</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center">
                <span data-toggle="tooltip" class="example-toggle"></span>
                <span data-toggle="tooltip" class="example-copy"></span>
              </div>
            </div>
          </div>
          <div class="card-body" data-app>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="mailgroups"
                :search="search"
                sort-by="id"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Поиск"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Добавить группу
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="4" sm="3" md="2">
                                <v-text-field
                                  v-model="editedItem.id"
                                  label="ID"
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-col cols="8" sm="9" md="10">
                                <v-text-field
                                  v-model="editedItem.key"
                                  label="Название"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" rows="2" sm="12" md="12">
                                <!--<<v-text-field
                                  v-model="editedItem.value"
                                  label="Название"
                                  id="users"
                                  autocomplete
                                  ref="users"
                                ></v-text-field>-->
                                <input
                                  type="text"
                                  id="users"
                                  ref="users"
                                  v-model="editedItem.value"
                                  autocomplete
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            Отменить
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="save">
                            Сохранить
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >Вы уверены, что хотите удалить группу?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Отмена</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >Да</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">
                    Сбросить
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import "@yaireo/tagify/dist/tagify.css";

export default {
  name: "mailgroups",

  data() {
    return {
      userList: [],
      tagify: null,
      selectedUsers: [],
      dialog: false,
      dialogDelete: false,
      search: "",
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "Название",
          sortable: true,
          value: "key"
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false
        }
      ],
      mailgroups: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        key: "",
        value: ""
      },
      defaultItem: {
        id: "",
        key: "",
        value: ""
      }
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      return this.editedIndex === -1 ? "Новая группа" : "Редактировать";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
    /*tagify (val) {
      if(val !== null) {
        this.tagify.on("add", function(e) {
          console.log(e.detail.data);
        });
      }
    }*/
  },

  created() {
    this.dialog = true;
    this.initialize();
    this.loadUsers();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Группы рассылок" }]);
    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },

  destroyed() {},

  methods: {
    initialize() {
      return new Promise(resolve => {
        ApiService.post("api/v1/findCustomData/", {
          user_id: this.$store.getters.currentUser.user.id,
          type: "mailer"
        }).then(({ data }) => {
          //    console.log(data);
          this.mailgroups = data;
          this.editedItem.id = this.getLastId();
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    loadUsers() {
      new Promise(resolve => {
        ApiService.get("api/v1/users").then(({ data }) => {
          for (let item of Object.values(data.results)) {
            const state = "danger";
            this.userList.push({
              id: item.id,
              value: item.first_name + " " + item.last_name,
              state: state,
              email: item.username,
              initials:
                item.first_name.charAt(0).toUpperCase() +
                item.last_name.charAt(0).toUpperCase(),
              code:
                item.first_name.charAt(0).toUpperCase() +
                item.last_name.charAt(0).toUpperCase(),
              editable: false
            });
          }
          this.dialog = false;
          //  console.log(this.$refs.users);
          this.loadTagify();
          //   console.log(this.$refs.users);
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    getLastId() {
      if (this.mailgroups.length) {
        return Math.max(...this.mailgroups.map(el => el.id)) + 1;
      } else {
        return 1;
      }
    },

    loadTagify() {
      var inputElem = document.getElementById("users");
      this.tagify = new Tagify(inputElem, {
        delimiters: ", ",
        whitelist: this.userList,
        enforceWhitelist: true,
        templates: {
          dropdownItem: function(tagData) {
            try {
              return (
                '<div class="tagify__dropdown__item" data-id="' +
                tagData.id +
                '">' +
                '<div class="kt-media-card">' +
                '    <span class="kt-media kt-media--' +
                (tagData.initialsState ? tagData.initialsState : "") +
                '">' +
                "        <span>" +
                tagData.initials +
                "</span>" +
                "    </span>" +
                '    <div class="kt-media-card__info">' +
                '        <a href="#" class="kt-media-card__title">' +
                tagData.value +
                "</a>" +
                '        <span class="kt-media-card__desc"> (' +
                tagData.email +
                ")</span>" +
                "    </div>" +
                "</div>" +
                "</div>"
              );
            } catch (err) {
              console.error(err);
            }
          }
        },
        transformTag: function(tagData) {
          tagData.class = "tagify__tag tagify__tag--brand";
        },
        dropdown: {
          classname: "color-blue",
          enabled: 1,
          maxItems: 5,
          searchKeys: ["value", "email"]
        }
        /*callbacks: {
                  add: function (tagData) {
                    console.log(tagData.detail.data);
                    this.selecteedUsers.push(tagData.detail.data);
                  },
                  remove: function (tagData) {
                    var index = this.selecteedUsers[inputElem.dataset.number].findIndex(x => x.id === tagData.detail.data.id);
                      this.selecteedUsers[inputElem.dataset.number].splice(index, 1);
                  }
                }*/
      });
    },

    editItem(item) {
      this.editedIndex = this.mailgroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(this.editedItem);
      item.value.maillist.forEach(element =>
        this.tagify.addTags([{ value: element.name, email: element.email }])
      );
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.mailgroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.mailgroups.splice(this.editedIndex, 1);
      return new Promise(resolve => {
        ApiService.delete("api/v1/customData/" + this.editedItem.id + "/").then(
          ({ data }) => {
            this.closeDelete();
            resolve(data);
          }
        );
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    close() {
      this.dialog = false;
      //console.log(document.getElementById("users").val());
      //console.log(this.tagify.value);
      this.tagify.removeAllTags();
      this.$nextTick(() => {
        this.defaultItem.id = this.getLastId();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.defaultItem.id = this.getLastId();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var emails = [];
      for (let item of Object.values(this.tagify.value)) {
        //   console.log(item.value, item.email);
        emails.push({
          name: item.value,
          email: item.email
        });
      }
      var credentials = {
        user_id: this.$store.getters.currentUser.user.id,
        type: "mailer",
        key: this.editedItem.key,
        value: { maillist: emails }
      };
      //  console.log(credentials);
      if (this.editedIndex > -1) {
        return new Promise(resolve => {
          ApiService.patch(
            "api/v1/customData/" + this.editedItem.id + "/",
            credentials
          ).then(({ data }) => {
            //     console.log(data);
            Object.assign(this.mailgroups[this.editedIndex], data);
            this.close();
            resolve(data);
          });
        }).catch(({ response }) => {
          console.error(response);
        });
      } else {
        return new Promise(resolve => {
          ApiService.post("api/v1/customData/", credentials).then(
            ({ data }) => {
              //    console.log(data);
              this.mailgroups.push(data);
              this.close();
              resolve(data);
            }
          );
        }).catch(({ response }) => {
          console.error(response);
        });
      }
    }
  }
};
</script>
